// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import HomeIcon from '@mui/icons-material/Home';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, IconButton, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { BadRequestError } from 'services/apiWrapper';
import ObservationHeader from './components/ObservationHeader';
import { useObservationEventInstanceList } from './hooks/ObservationEventHooks';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';
import ObservationPageContainer from './components/ObservationPageContainer';
import SessionListHeader from './components/SessionListHeader';
import SessionListCard from './components/SessionListCard';
import Heading3 from 'components/common/subcomponents/Typography/Heading3';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const ObservationSessionList = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const [eventId, setEventId] = useState(null);
  const [event, setEvent] = useState(null);
  const [prev, setPrev] = useState(null);
  const [instanceList, setInstanceList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const eventInstanceListQuery = useObservationEventInstanceList(eventId);

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);

  const isFetchingCount = useIsFetching();

  useEffect(() => {
    if (eventInstanceListQuery.isSuccess && eventInstanceListQuery.isFetching === false) {
      setEvent(eventInstanceListQuery.data.event);
      setInstanceList(eventInstanceListQuery.data.instances);
    }
    if (eventInstanceListQuery.isError) {
      logClientException(eventInstanceListQuery.error);
      if (eventInstanceListQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (eventInstanceListQuery.error instanceof BadRequestError) {
        setErrorMessage(eventInstanceListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [eventInstanceListQuery]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const id = queryParams?.get('id');
      if (id && id?.length > 0) {
        setEventId(id);
      }
      const p = queryParams?.get('p');
      if (p && p?.length > 0) {
        setPrev(p);
      }
    }
  }, [queryParams?.get('id')]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (initialized && (!eventId || !event) && eventInstanceListQuery.isSuccess && eventInstanceListQuery.isFetching === false) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          paddingBottom: '3rem',
        }}
      >
        <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='small' />
        <ObservationPageContainer>
          <div>{fields?.noIdMessage || 'You appear to have reached this page in error.'}</div>
          <KitButton
            size='sm'
            style={{
              width: '100px',
            }}
            href={`${fields?.eventListLink?.href}?r=1`}
          >
            <TurnLeftIcon />
            Back
          </KitButton>
        </ObservationPageContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='large'>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
          <SessionListHeader event={event} sessionList={instanceList} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <KitButton
              size='sm'
              style={{
                width: prev === 'summary' ? '195px' : '160px',
              }}
              disabled={isFetchingCount > 0}
              href={prev === 'summary' ? `event-summary?id=${eventId}&p=${prev}` : `${fields?.eventListLink?.href}?r=1`}
            >
              <TurnLeftIcon />
              {prev === 'summary' ? 'RETURN TO EVENT SUMMARY' : 'RETURN TO EVENT LIST'}
            </KitButton>
          </Box>
        </Box>
      </ObservationHeader>
      <Box>
        <IconButton aria-label='back' href={fields.observationHomeLink.href} title={fields.observationHomeLink.text} color='primary'>
          <KeyboardBackspaceIcon /> <HomeIcon />
        </IconButton>
      </Box>
      <ObservationPageContainer>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center', gap: '1.5rem', flexWrap: 'wrap', marginTop: '1.5rem' }}>
          {instanceList?.length === 0 && <Heading3 underline={false}>No sessions found</Heading3>}

          {instanceList &&
            instanceList.length > 0 &&
            instanceList.map((session, index) => (
              <SessionListCard key={index} event={event} session={session} sessionSummaryLink={fields.sessionSummaryLink} />
            ))}
        </Box>

        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0 || eventInstanceListQuery.isFetching} />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationSessionList;
