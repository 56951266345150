// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import { Box, Skeleton, Snackbar, Tabs } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { useRouter } from 'next/router';
import { BadRequestError } from 'services/apiWrapper';
import { removeSessionStoreItem, setSessionStoreString } from 'services/storageUtils';
import ObservationHeader from './components/ObservationHeader';
import ObservationPageContainer from './components/ObservationPageContainer';
import TabPanel from './components/TabPanel';
import { TabItem } from './components/Tabs';
import CorrectionBenchmarkGroup from './components/sessionSummary/CorrectionBenchmarkGroup';
import ObservationHeaderDetail from './components/sessionSummary/ObservationHeaderDetail';
import PraiseBenchmarkGroup from './components/sessionSummary/PraiseBenchmarkGroup';
import PreventionBenchmarkGroup from './components/sessionSummary/PreventionBenchmarkGroup';
import { useAddObservationInstance, useObservationInstanceById } from './hooks/ObservationInstanceHooks';
import { useObservationStore } from './stores/observationStore';
import ObservationStats from './utils/ObservationStats';
import SummaryMetricCards from './components/sessionSummary/SummaryMetricCards';
import StrengthsGrowthGroup from './components/sessionSummary/StrengthsGrowthGroup';
import ObservationTimelineFull from './components/dataEntry/ObservationTimelineFull';
import { default as SavedSnack } from './components/AlertSnack';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const ObservationSessionSummaryReview = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const router = useRouter();
  const [obsId, setObsId] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [observation, setObservation] = useState(null);
  const [queryDataUpdated, setQueryUpdated] = useState(0);

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(window.location.search);

  const observationInstanceQuery = useObservationInstanceById(obsId);
  const { setObservation: setObservationStore } = useObservationStore();

  const isFetchingCount = useIsFetching();

  const observationMetrics = new ObservationStats(observation);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      const id = queryParams?.get('id');
      if (id && id?.length > 0) {
        setObsId(id);
      }
    }
  }, [queryParams?.get('id')]);

  useEffect(() => {
    if (observationInstanceQuery.isSuccess && observationInstanceQuery.isFetching === false) {
      if (observationInstanceQuery.dataUpdatedAt > queryDataUpdated) {
        setObservation(observationInstanceQuery.data);
        setObservationStore(observationInstanceQuery.data);
        setQueryUpdated(observationInstanceQuery.dataUpdatedAt);
      }
    }
    if (observationInstanceQuery.isError) {
      logClientException(observationInstanceQuery.error);
      if (observationInstanceQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (observationInstanceQuery.error instanceof BadRequestError) {
        setErrorMessage(observationInstanceQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [observationInstanceQuery]);

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (initialized && (!obsId || !observation) && observationInstanceQuery.isSuccess && observationInstanceQuery.isFetching === false) {
    return (
      <Box
        sx={{
          backgroundColor: theme.palette.obsBlue.light,
          paddingBottom: '3rem',
        }}
      >
        <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='small' />
        <ObservationPageContainer>
          <div>{fields?.noIdMessage || 'You appear to have reached this page in error.'}</div>
          <KitButton
            size='sm'
            style={{
              width: '100px',
            }}
            href={`${fields?.sessionListLink?.href}?r=1`}
          >
            <TurnLeftIcon />
            Back
          </KitButton>
        </ObservationPageContainer>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} size='xlarge'>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        {observation && (
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <ObservationHeaderDetail observation={observation} observationMetrics={observationMetrics} doInfoUpdate={true} />
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' } }}>
              <KitButton
                size='sm'
                style={{
                  width: '180px',
                }}
                href={`${fields?.sessionListLink?.href}?id=${observation?.event_id}`}
              >
                <TurnLeftIcon />
                {fields?.sessionListLink?.text}
              </KitButton>
            </Box>
          </Box>
        )}
      </ObservationHeader>

      <ObservationPageContainer>
        {!observation && (
          <>
            <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
            <Skeleton variant='rounded' sx={{ width: '100%', fontSize: '18px' }} />
            <Skeleton variant='rounded' sx={{ width: '100%', fontSize: '24px' }} />
            <Skeleton variant='text' sx={{ width: '100%', fontSize: '24px' }} />
            <Skeleton variant='text' sx={{ width: '100%', fontSize: '18px' }} />
            <Skeleton variant='rounded' sx={{ width: '100%', fontSize: '24px' }} />
          </>
        )}

        <Box sx={{ display: observation ? 'flex' : 'none', flexWrap: 'wrap', gap: '.5rem', justifyContent: 'center' }}>
          <SummaryMetricCards observationMetrics={observationMetrics} checklistPageHref={fields?.checklistPage?.href} />

          <Box sx={{ width: '100%', marginTop: '1.25rem' }}>
            <Tabs
              value={tabIndex}
              onChange={(e, nv) => setTabIndex(nv)}
              aria-label='Summary Tabs'
              centered
              variant='fullWidth'
              sx={{ borderBottom: `solid 2px ${theme.palette.primary.main}` }}
            >
              <TabItem label='Strengths/Growth' id='strengths-growth-tab' aria-controls='strengths-growth-tabpanel' />
              <TabItem label='Benchmarks' id='benchmarks-tab' aria-controls='benchmarks-tabpanel' />
              <TabItem label='Timeline' id='timeline-tab' aria-controls='timeline-tabpanel' />
            </Tabs>
            <TabPanel value={tabIndex} index={0} id='strengths-growth-tabpanel' name='strengths-growth-tabpanel'>
              <StrengthsGrowthGroup showUpdate={true} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1} id='benchmarks-tabpanel' name='benchmarks-tabpanel'>
              <PreventionBenchmarkGroup observationMetrics={observationMetrics} />
              <PraiseBenchmarkGroup observationMetrics={observationMetrics} />
              <CorrectionBenchmarkGroup observationMetrics={observationMetrics} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2} id='timeline-tabpanel' name='timeline-tabpanel'>
              <ObservationTimelineFull canEdit={false} />
            </TabPanel>
          </Box>
        </Box>

        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0} />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </ObservationPageContainer>
    </Box>
  );
};

export default ObservationSessionSummaryReview;
