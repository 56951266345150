import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import { useTheme } from '@emotion/react';

const ConfirmDeleteDialog = ({ title, confirmMessage, open, handleClose, handleYes, noButtonColor, yesButtonColor }) => {
  const theme = useTheme();
  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <DialogTitle id='confirm-delete'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{confirmMessage}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'space-evenly', marginBottom: '20px' }}>
        <Button
          variant='contained'
          sx={{ width: '110px', backgroundColor: noButtonColor ? noButtonColor : theme.palette.primary.main }}
          onClick={() => handleClose()}
        >
          No
        </Button>
        <Button
          variant='contained'
          sx={{ width: '110px', backgroundColor: yesButtonColor ? yesButtonColor : theme.palette.secondary.main }}
          color='secondary'
          onClick={() => handleYes()}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDeleteDialog.defaultProps = {
  title: 'Confirm Delete',
  confirmMessage: 'Are you sure you want to delete this item?',
};

ConfirmDeleteDialog.propTypes = {
  title: PropTypes.string,
  confirmMessage: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleYes: PropTypes.func.isRequired,
  noButtonColor: PropTypes.string,
  yesButtonColor: PropTypes.string,
};

export default ConfirmDeleteDialog;
