import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles } from '../StyledComponents';
import OffTaskDialog from './OffTaskDialog';
import Dialog from './OffTaskDialog';

const ObservationOffTask = () => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const [intervalTimer, setIntervalTimer] = useState(null);
  const [showOffTask, setShowOffTask] = useState(false);
  const [offTaskNumber, setOffTaskNumber] = useState('');
  const [offTaskNumberError, setOffTaskNumberError] = useState(null);

  const { observation, addTimelineObservation } = useObservationStore();

  const addToTimeline = (isClosed = false) => {
    if (
      isClosed ||
      (!isNaN(offTaskNumber) && Number.parseInt(offTaskNumber, 10) >= 0 && Number.parseInt(offTaskNumber, 10) <= observation.number_of_students)
    ) {
      const timelineNote = {
        timestamp: new Date().getTime(),
        type: 'Off Task',
        tags: [isClosed ? 'X' : offTaskNumber.toString()],
        count: isClosed ? -1 : Number.parseInt(offTaskNumber, 10),
        number_of_students: observation.number_of_students,
      };
      addTimelineObservation(timelineNote);
      setShowOffTask(false);
      setOffTaskNumber('');
      setOffTaskNumberError(false);
    }
  };

  useEffect(() => {
    if (
      observation.timer &&
      observation.timer.length > 0 &&
      observation.timer[0].timer_start &&
      observation.timer[observation.timer.length - 1]?.timer_end === 0
    ) {
      const timer = setInterval(() => {
        let elapsedMS = 0;
        for (let i = 0; i < observation.timer.length; i++) {
          if (observation.timer[i].timer_end === 0) {
            elapsedMS += Date.now() - observation.timer[i].timer_start;
          } else {
            elapsedMS += observation.timer[i].timer_end - observation.timer[i].timer_start;
          }
        }
        const elapsedSeconds = elapsedMS / 1000;
        if (Math.floor(elapsedSeconds % 60) === 0 && showOffTask === false) {
          setShowOffTask(true);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [observation.timer]);

  const closeDialog = () => {
    setShowOffTask(false);
  };

  return (
    <main>
      <OffTaskDialog
        style={styles}
        open={showOffTask}
        closeConfirm={closeDialog}
        observation={observation}
        offTaskNumber={offTaskNumber}
        setOffTaskNumber={setOffTaskNumber}
        offTaskNumberError={offTaskNumberError}
        setOffTaskNumberError={setOffTaskNumberError}
        addToTimeline={addToTimeline}
        studentCount={observation.number_of_students}
      />
    </main>
  );
};

export default ObservationOffTask;
