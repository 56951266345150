import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { useSession } from 'next-auth/react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { AttachFile, Attachment, Close } from '@mui/icons-material';

import { callApi, BadRequestError } from 'services/apiWrapper';
import { logClientException } from 'appinsights/clientAppInsights';
import { arrayBufferToBase64, emailRegex } from '/services/stringUtils';
import { ERROR_MESSAGES, LEAD_TITLES, OTHER_DISTRICT, OTHER_SCHOOL } from 'services/constants';
import PleaseWait from 'components/common/PleaseWait';
import { addDataLayerEvent } from 'services/dataLayerUtils';
import { Typography } from '@mui/material';
import ReCAPTCHA from 'react-google-recaptcha';
import { getAgencyData } from 'services/userUtils';
import { states, stripChars } from 'services/stringUtils';
import SchoolSelector from './SchoolSelector';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY;
const FORM_NAME = 'Contact Us';
const FORM_ID = '346c4fed97c648fb822f42788f256fb1';

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 15px 10px 15px;
  @media screen and (min-width: 992px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 991px) {
    justify-content: space-between;
  }
`;

export const ThankYou = styled.div`
  font-weight: 600;
  margin: 15px;
  @media screen and (min-width: 992px) {
    font-size: 1.55rem;
    line-height: 1.75rem;
  }
  @media screen and (max-width: 991px) {
    font-size: 1.35rem;
    line-height: 1.675rem;
  }
`;

export const Hours = styled.div`
  font-size: 1.15rem;
  line-height: 1.375rem;
  padding: 0 15px;
`;

const ALLOWED_FILE_TYPES = [
  'image/png',
  'image/jpg',
  'image/jpeg',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
];
const ALLOWED_FILE_DESCRIPTION = 'Images (png, jpg), PDF, Word and Excel';
const MAX_ATTCHMENTS_SIZE_MB = process.env.NEXT_PUBLIC_MAX_ATTACHMENTS_SIZE_MB || 5;

const ContactUsForm = (props) => {
  const formRef = useRef(null);

  const { data: session, status } = useSession();

  const [errorMessage, setErrorMessage] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [attemptedSubmit, setAttemptedSubmit] = useState(false);

  const queryParams = typeof window === 'undefined' ? null : new URLSearchParams(location.search);
  const source = queryParams?.get('source');

  const [formState, setFormState] = useState({
    type: (props.product && props.product.length > 0) || source === 'callout' ? 'sales' : '',
    email: '',
    first_name: '',
    last_name: '',
    title: '',
    agency: '',
    phone: '',
    description: '',
    previous_ticket: 'No',
    ticket_number: '',
    screenshot: null,
    recaptcha_token: '',
    city: '',
    state: '',
  });

  const resetForm = () => {
    formRef?.current?.reset();
    setFormState({
      type: (props.product && props.product.length > 0) || source === 'callout' ? 'sales' : '',
      email: '',
      first_name: '',
      last_name: '',
      title: '',
      agency: '',
      phone: '',
      description: '',
      previous_ticket: 'No',
      ticket_number: '',
      screenshot: null,
      recaptcha_token: '',
      city: '',
      state: '',
    });
  };

  useEffect(() => {
    const sessionInit = (user) => {
      if (!user) {
        return;
      }
      //const agencyData = await getAgencyData(user);
      setFormState({
        ...formState,
        state: user.state,
        school_id: user.school_id,
        school_name: user.school_name,
        district_id: user.district_id,
        other_city: user.other_city,
        other_agency: user.other_agency,
        first_name: user?.firstName ?? '',
        last_name: user?.lastName ?? '',
        email: user?.email ?? '',
        title: user?.position ?? '',
      });
    };
    sessionInit(session?.user);
  }, [session?.user]);

  useEffect(() => {
    if (typeof window !== 'undefined' && !window.dataLayer?.find((d) => d.event === 'contact_form_view')) {
      // add contact_form_view event
      let event = {
        'event': 'contact_form_view',
        'form_name': FORM_NAME,
        'form_id': FORM_ID,
      };
      if (props.method) {
        event.trainingMethod = props.method;
      }
      if (props.product) {
        event.product = props.product;
      }
      if (source === 'callout') {
        event.from_callout = true;
      }
      addDataLayerEvent(event);
    }
  }, []);

  const getOptionFromMethod = (method) => {
    switch (method?.toLowerCase()) {
      case '12-month online access': // wms
        return '924790007';
      case 'two-day live virtual': // wms
        return '924790006';
      case 'instructor-led virtual':
      case 'instructor-led virtual two-day training':
        return '924790006';
      case 'instructor-led virtual five-day training':
        return '924790006';
      case 'in-person at your school':
        return '924790008';
      case 'on-site personalized training':
        return '924790008';
      case 'coaching & consultation':
        return '924790012';
      default:
        return '924790011'; // Contact Sales
    }
  };

  const sendForm = async () => {
    setAttemptedSubmit(true);

    // required fields
    if (!formState.email || formState.email.length === 0) {
      return;
    } else if (!emailRegex.test(formState.email)) {
      return;
    } else if (!formState.first_name || formState.first_name.length === 0) {
      return;
    } else if (!formState.last_name || formState.last_name.length === 0) {
      return;
    } else if (!formState.description || formState.description.length === 0) {
      return;
    } else if (!formState.type || formState.type.length === 0) {
      return;
    } else if (!formState.recaptcha_token || formState.recaptcha_token.length === 0) {
      return;
    }

    let agency = null;
    if (formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id) {
      agency = formState.schoolInfo?.other_agency;
    } else if (formState.schoolInfo?.school && formState.schoolInfo?.school?.value?.id !== OTHER_SCHOOL.id) {
      agency = formState.schoolInfo?.school?.value.name;
    } else {
      agency = formState.schoolInfo?.district?.value.name;
    }

    // require title for sales type
    if (formState.type === 'sales') {
      if (!formState.title || formState.title.length === 0) {
        return;
      }
      if (
        formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id &&
        (!formState.schoolInfo?.other_city || formState.schoolInfo?.other_city.length === 0)
      ) {
        setFormState({ ...formState, other_cityError: true });
        return;
      }
      if (!formState.schoolInfo?.state) {
        setFormState({ ...formState, stateError: true });
        return;
      }
      if (!formState.schoolInfo?.district) {
        setFormState({ ...formState, districtError: true });
        return;
      }
      if (
        formState.schoolInfo?.district &&
        formState.schoolInfo?.district.value.id !== OTHER_DISTRICT.id &&
        !session?.user && // if user is logged in, we don't need to check for school
        !formState.schoolInfo?.school
      ) {
        setFormState({ ...formState, schoolError: true });
        return;
      }
    }

    if (!agency || agency.length === 0) {
      setFormState({
        ...formState,
        stateError: false,
        districtError: formState.schoolInfo?.district ? false : true,
        schoolError: formState.schoolInfo?.school ? false : true,
        other_agencyError: formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id ? true : false,
      });
      return;
    }

    try {
      setFetching(true);
      const formData = new FormData(formRef.current);
      formData.append('recaptcha_token', formState.recaptcha_token);
      formData.append('agency', agency);

      if (formState.type === 'sales') {
        await submitLead();
      } else {
        await postForm(formData);
      }

      // add contact_form_submit event
      let event = {
        'event': 'contact_form_submit',
        'form_name': FORM_NAME,
        'form_id': FORM_ID,
        'form_reason': formState.type,
      };
      if (props.method) {
        event.trainingMethod = props.method;
      }
      if (props.product) {
        event.product = props.product;
      }
      addDataLayerEvent(event);

      setFormSubmitted(true);
      setFetching(false);
    } catch (error) {
      console.log(error);
      logClientException(error);
      setFetching(false);
      if (error instanceof BadRequestError) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(ERROR_MESSAGES.Default);
      }
    }
  };

  const postForm = async (body) => {
    let response;
    try {
      // for (const [key, value] of body) {
      //   console.log(`${key}: ${value}`);
      // }

      response = await fetch('/api/contact-us', { method: 'POST', body, mode: 'cors' });
      if (response.ok) {
        if (response.status === 204) {
          return;
        }
      }
      if (response.status === 400) {
        throw new BadRequestError(await response.text());
      }
      let err = new Error(`API Call failed. Status ${response.status} ${response.statusText}`);
      throw err;
    } catch (error) {
      console.log('callApi error', error);
      throw error;
    }
  };

  const submitLead = async () => {
    if (typeof window === 'undefined') {
      return;
    }
    try {
      const body = {
        description: formState.description,
        first_name: formState.first_name,
        last_name: formState.last_name,
        title: formState.title,
        email: formState.email,
        decision_maker: LEAD_TITLES.find((t) => t.title === formState.title)?.decision_maker,
        phone: formState.phone,
        option: getOptionFromMethod(props.method),
        product: props.product,
        utm_source: window.sessionStorage?.utm_source,
        utm_medium: window.sessionStorage?.utm_medium,
        utm_campaign: window.sessionStorage?.utm_campaign,
        utm_content: window.sessionStorage?.utm_content,
        recaptcha_token: formState.recaptcha_token,
        state: formState.schoolInfo?.state?.value,
      };
      if (formState.schoolInfo?.district?.value?.id === OTHER_DISTRICT.id) {
        body.other_agency = formState.schoolInfo?.other_agency;
        body.other_city = formState.schoolInfo?.other_city;
        body.agencyType = 'OTHER';
      } else if (formState.schoolInfo?.school && formState.schoolInfo?.school?.value?.id !== OTHER_SCHOOL.id) {
        body.agencyType = 'SCHOOL';
        body.agency_id = formState.schoolInfo?.school?.value?.agile_ed_id;
      } else {
        body.agencyType = 'DISTRICT';
        body.agency_id = formState.schoolInfo?.district?.value?.agile_ed_id;
      }

      try {
        await callApi('/api/contact-us-crm-lead', 'POST', body);
        setFormSubmitted(true);
        resetForm();
      } catch (error) {
        console.log(error);
        logClientException(error);
        if (error instanceof BadRequestError) {
          setErrorMessage(error.message);
          return;
        } else {
          setErrorMessage(ERROR_MESSAGES.Default);
        }
      }
    } catch (error) {
      console.log(error);
      logClientException(error);
    }
  };

  const addAttachments = async (files) => {
    if (!files || files.length === 0) {
      return;
    }
    setErrorMessage(null);
    const f = files[0];
    if (ALLOWED_FILE_TYPES.indexOf(f.type) === -1) {
      setErrorMessage(`Only ${ALLOWED_FILE_DESCRIPTION} files are allowed!`);
      return;
    }
    if (f.size > MAX_ATTCHMENTS_SIZE_MB * 1024 * 1024) {
      setErrorMessage(`Cannot attach files larger than ${MAX_ATTCHMENTS_SIZE_MB} MB!`);
      return;
    }
    const data = arrayBufferToBase64(await f.arrayBuffer());

    setFormState({
      ...formState,
      screenshot: {
        data,
        file_name: f.name,
        file_type: f.type,
        number: data && data.length > 0 ? 1 : 0,
      },
    });
  };

  const removeAttachment = () => {
    setFormState({
      ...formState,
      screenshot: null,
    });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  if (formSubmitted) {
    return (
      <Box sx={{ width: '100%', height: '196px' }}>
        <ThankYou>Thank you for your request. A member of our team will get back to you within the next business day.</ThankYou>
        <Hours>Our hours of operation are M-F 8am to 5pm CT.</Hours>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: '#fff' }}>
      <PleaseWait isLoading={fetching} />
      <Snackbar open={errorMessage && errorMessage.length > 0} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>

      <form ref={formRef}>
        <div style={{ padding: '0 15px' }}>
          <FormControl fullWidth sx={{ marginTop: '7px' }}>
            <InputLabel
              style={{ padding: '-10px', marginBottom: '5px', marginLeft: -15 }}
              required
              error={attemptedSubmit && (formState.type === undefined || formState.type.length === 0)}
              id='type-select'
            >
              How can we help you?
            </InputLabel>
            <Select
              name='type'
              variant='standard'
              labelId='type-select'
              id='type-select-value'
              value={formState.type}
              label='Type of issue'
              onChange={(e) => {
                setFormState({ ...formState, type: e.target.value });
              }}
            >
              <MenuItem value='sales' key='sales'>
                Contact Sales
              </MenuItem>
              <MenuItem value='billing' key='billing'>
                Billing
              </MenuItem>
              <MenuItem value='cancel' key='cancel'>
                Cancellation
              </MenuItem>
              <MenuItem value='technical' key='technical'>
                Technical
              </MenuItem>
              <MenuItem value='other' key='other'>
                Other
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: 20, padding: '0 15px' }}>
          <TextField
            name='first_name'
            value={formState.first_name}
            error={attemptedSubmit && (formState.first_name === undefined || formState.first_name.length === 0)}
            required
            margin='dense'
            id='first_name'
            label='First Name'
            type='text'
            fullWidth
            variant='standard'
            onChange={(e) => setFormState({ ...formState, first_name: stripChars(e.target.value) })}
            inputProps={{ maxLength: 40 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name='last_name'
            value={formState.last_name}
            error={attemptedSubmit && (formState.last_name === undefined || formState.last_name.length === 0)}
            required
            margin='dense'
            id='last_name'
            label='Last Name'
            type='text'
            fullWidth
            variant='standard'
            onChange={(e) => setFormState({ ...formState, last_name: stripChars(e.target.value) })}
            inputProps={{ maxLength: 40 }}
            InputLabelProps={{ shrink: true }}
          />
          {formState.type === 'sales' && (
            <div style={{ marginTop: 15 }}>
              <FormControl fullWidth>
                <InputLabel
                  style={{ padding: '-10px', marginBottom: '5px', marginLeft: -15 }}
                  required
                  error={attemptedSubmit && formState.type === 'sales' && (formState.title === undefined || formState.title.length === 0)}
                  id='type-select'
                >
                  Title
                </InputLabel>
                <Select
                  name='title'
                  variant='standard'
                  labelId='title-select-label'
                  id='title-select-value'
                  //size='small'
                  value={formState.title}
                  required
                  onChange={(e) => {
                    setFormState({ ...formState, title: e.target.value });
                  }}
                >
                  <MenuItem value='' disabled>
                    Select Title
                  </MenuItem>
                  {LEAD_TITLES.map((t) => {
                    return (
                      <MenuItem value={t.title} key={t.title}>
                        {t.title}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
          )}
          <TextField
            name='email'
            value={formState.email}
            error={attemptedSubmit && (formState.email === undefined || formState.email.length === 0 || !emailRegex.test(formState.email))}
            required
            margin='dense'
            id='email'
            label='Email'
            type='email'
            fullWidth
            variant='standard'
            onChange={(e) => setFormState({ ...formState, email: stripChars(e.target.value?.toLowerCase()) })}
            inputProps={{ maxLength: 40 }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            name='phone'
            value={formState.phone}
            margin='dense'
            id='phone'
            label='Phone Number'
            type='tel'
            fullWidth
            variant='standard'
            onChange={(e) => setFormState({ ...formState, phone: stripChars(e.target.value) })}
            inputProps={{ maxLength: 20, pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im }}
            InputLabelProps={{ shrink: true }}
          />
          <div style={{ display: session?.user ? 'none' : 'block' }}>
            <SchoolSelector
              state={formState.state}
              district_id={formState.district_id}
              school_id={formState.school_id}
              school_name={formState.school_name}
              other_city={formState.other_city}
              other_agency={formState.other_agency}
              requireCity={formState.type === 'sales'}
              stateError={formState.stateError}
              districtError={formState.districtError}
              schoolError={formState.schoolError}
              other_cityError={formState.other_cityError}
              other_agencyError={formState.other_agencyError}
              onDataChange={(e) => {
                setFormState({ ...formState, schoolInfo: e });
              }}
            />
          </div>
          <TextField
            name='description'
            value={formState.description}
            error={attemptedSubmit && (!formState.description || formState.description.length === 0)}
            multiline={true}
            rows={3}
            required
            margin='dense'
            id='description'
            label='Description'
            type='text'
            fullWidth
            variant='standard'
            onChange={(e) => setFormState({ ...formState, description: stripChars(e.target.value, true) })}
            inputProps={{ maxLength: 4000 }}
            InputLabelProps={{ shrink: true }}
          />{' '}
        </div>
        {/* IMPORTANT: Hiding attachment button must be done in style with 'none' or the attachment will NOT be included in the email! */}
        <div style={{ margin: '10px 15px 10px 15px' }}>
          {formState.type !== 'sales' && (
            <Button
              component='label'
              title='Attach a screenshot'
              variant='outlined'
              startIcon={<AttachFile />}
              color='info'
              size='sm'
              style={{ borderRadius: '30px', display: formState?.screenshot ? 'none' : 'inline-block' }}
            >
              Attachment
              <input onChange={(event) => addAttachments(event.target.files)} type='file' hidden id='contained-button-file' name='screenshot' />
            </Button>
          )}
        </div>
        {formState?.screenshot?.data && formState?.screenshot?.data.length > 0 && (
          <div style={{ marginLeft: 20 }}>
            <strong>Attachment:</strong> {formState && formState.screenshot && formState.screenshot.file_name}{' '}
            <KitButton simple size='sm' onClick={(e) => removeAttachment()}>
              <Close style={{ color: '#000' }} />
            </KitButton>
          </div>
        )}
        <div style={{ marginLeft: 20 }}>
          <ReCAPTCHA sitekey={`${SITE_KEY}`} onChange={(token) => setFormState({ ...formState, recaptcha_token: token })} />
        </div>
        <ButtonContainer>
          <KitButton
            disabled={!formState.recaptcha_token?.length > 0}
            style={{ width: '130px' }}
            color='success'
            round
            variant='outlined'
            onClick={(e) => sendForm()}
          >
            Contact Us
          </KitButton>
        </ButtonContainer>
      </form>
    </Box>
  );
};

export default ContactUsForm;
